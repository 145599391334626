<template>
  <section class="cont pictureSetting">
    <el-row class="crumbs-box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>业务中台</el-breadcrumb-item>
        <el-breadcrumb-item>小程序配置</el-breadcrumb-item>
        <el-breadcrumb-item>图片配置</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>

    <el-row class="content-box">
      <el-row class="table-box">
        <el-button class="bg-gradient" style="position: absolute; right: 0" type="primary" @click="handleAdd"><span>添加图片</span></el-button>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="首页广告" name="first">
            <el-table :data="tableData" border fit :stripe="true">
              <el-table-column type="index" width="100" label="序号"></el-table-column>
              <el-table-column label="图片" min-width="30">
                <template slot-scope="scope">
                  <img :src="scope.row.url" alt="" style="width: 100px" />
                </template>
              </el-table-column>
              <el-table-column label="操作" min-width="30">
                <template slot-scope="scope">
                  <el-button type="text" @click="handleEdit(scope.row)">
                    <el-link type="primary">编辑</el-link>
                  </el-button>
                  <el-button type="text" @click="handleDelete(scope.row)">
                    <el-link type="danger">删除</el-link>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>

          <el-tab-pane label="房型详情首图" name="second">
            <el-table :data="tableData" border fit :stripe="true">
              <el-table-column type="index" width="100" label="序号"></el-table-column>
              <el-table-column label="图片" min-width="30">
                <template slot-scope="scope">
                  <img :src="scope.row.url" alt="" style="width: 100px" />
                </template>
              </el-table-column>
              <el-table-column label="状态" min-width="30">
                <template slot-scope="scope">
                  <span v-if="scope.row.status === 'ENABLED'">已开启</span>
                  <span v-else style="color: red">已关闭</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" min-width="30">
                <template slot-scope="scope">
                  <el-button type="text" @click="handleEdit(scope.row)">
                    <el-link type="primary">编辑</el-link>
                  </el-button>
                  <el-button type="text" @click="handleDelete(scope.row)">
                    <el-link type="danger">删除</el-link>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </el-row>
    </el-row>
    <!-- 上传图片弹窗 -->
    <el-dialog :title="addEdit === 'add' ? '添加图片' : '修改图片'" :visible.sync="isShow" width="30%" @close="closeDialog">
      <div class="label m-bottom-20">
        <span>图片位置</span>
        <span class="m-left-20">{{ activeName === 'first' ? '首页广告' : '房型详情首图' }}</span>
      </div>
      <div class="label m-bottom-20">
        <div class="text">图片</div>
        <el-button type="text" style="margin-left: 20px; font-size: 16px" @click="visibleImage = true" v-if="url === ''">上传图片</el-button>
        <div v-else style="display: inline-block">
          <img :src="url" alt="" style="width: 100px; margin-left: 20px" />
          <el-button type="text" style="margin-left: 20px; font-size: 16px" @click="visibleImage = true">更改</el-button>
        </div>
      </div>
      <div class="label" v-if="activeName === 'second'">
        <div class="text">状态</div>
        <el-switch v-model="value" active-color="#13ce66" style="margin-left: 20px"></el-switch>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShow = false">取消</el-button>
        <el-button type="primary" @click="save">确认</el-button>
      </span>
    </el-dialog>

    <!-- 图片上传 -->
    <Cropper :visible="visibleImage" @setImageInfo="getImageInfo" @closeUploadImg="closeUploadImg" :filmSize="filmSize"></Cropper>
  </section>
</template>

<script>
  import { axios_getPage, axios_add, axios_modify, axios_remove } from '@/api/business/wechatSetting/pictureSetting'
  import Cropper from '@/components/local/Cropper'
  import { mapState } from 'vuex'
  export default {
    name: 'pictureSetting',
    components: { Cropper },
    props: {},
    data() {
      return {
        activeName: 'first',
        tableData: [],    // 表格数据

        visibleImage: false,
        isShow: false,    // 弹窗显示
        addEdit: '',      // 弹窗标题
        id: '',           // 编辑ID
        url: '',          // 图片地址
        value: false,     // 状态

        prompt: '提示！',
        del_success: '删除成功！',
        add_success: '添加成功！',
        update_success: '修改成功！',
        confirm_remove: '确定要删除吗？',
      }
    },
    computed: {
      ...mapState(['hotelInfo']),
      // 图片裁剪尺寸
      filmSize() {
        if (this.activeName === 'first') {
          return {
            autoCropWidth: 400,
            autoCropHeight: 300,
            fixedNumber: [4, 3],
            mode: '400px 300px',
          }
        } else {
          return {
            autoCropWidth: 400,
            autoCropHeight: 200,
            fixedNumber: [2, 1],
            mode: '400px 200px',
          }
        }
      },
    },
    mounted() {
      this.getData()
    },
    methods: {
      // 标签页切换
      handleClick() {
        this.getData()
      },
      // 查询
      getData() {
        const params = {
          hotelId: this.hotelInfo.id, // 酒店ID
          type: this.activeName === 'first' ? 'INDEX' : 'HOME_TYPE',
        }
        axios_getPage(params).then(({ success, records }) => {
          if (success) {
            this.tableData = records
          }
        })
      },
      // 添加
      handleAdd() {
        if (this.activeName === 'first' && this.tableData.length < 1) {
          this.isShow = true
          this.addEdit = 'add'
        } else if (this.activeName === 'second' && this.tableData.length < 10) {
          this.isShow = true
          this.addEdit = 'add'
        } else {
          this.$message({ message: '图片已满，无法增加！', type: 'warning' })
        }
      },
      // 关闭上传图片会话框
      closeUploadImg() {
        this.visibleImage = false
      },
      // 获取上传图片信息
      getImageInfo({ url }) {
        this.visibleImage = false
        this.url = url
      },
      // 关闭弹窗时触发
      closeDialog() {
        this.isShow = false
        this.url = ''
      },
      // 编辑
      handleEdit({ url, id }) {
        this.url = url
        this.isShow = true
        this.addEdit = 'edit'
        this.id = id
      },
      // 保存
      save() {
        let data = {
          hotelId: this.hotelInfo.id, // 酒店ID
          type: this.activeName === 'first' ? 'INDEX' : 'HOME_TYPE',
          url: this.url,
        }
        if (this.activeName === 'second') {
          data.status = this.value ? 'ENABLED' : 'DISABLED'
        }
        if (this.addEdit === 'add') {
          axios_add(data).then(({ success }) => {
            if (success) {
              this.closeDialog()
              this.$message({ message: this.add_success, type: 'success' })
              this.getData()
            }
          })
        } else {
          data.id = this.id
          axios_modify(data).then(({ success }) => {
            if (success) {
              this.closeDialog()
              this.$message({ message: this.update_success, type: 'success' })
              this.getData()
            }
          })
        }
      },
      // 删除
      handleDelete({ id }) {
        this.$confirm(this.confirm_remove, this.prompt, {
          confirmButtonText: this.confirm,
          cancelButtonText: this.cancel,
          type: 'warning',
        }).then(() => {
          axios_remove({ id }).then((res) => {
            if (res.success) {
              this.$message({ message: this.del_success, type: 'success' })
              this.getData()
            }
          })
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .pictureSetting {
    ::v-deep .el-tabs__header {
      width: 50vw;
    }
    .label {
      margin: 0 20px;
      font-size: 16px;
      .text {
        width: 64px;
        display: inline-block;
        text-align: end;
      }
    }
  }
</style>
