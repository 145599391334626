import request from "@/api/service";
import qs from 'qs'

const api = {
   page: '/event/appImgConfig/getList',
   add:'/event/appImgConfig/add',
   modify:'/event/appImgConfig/modify',
   remove:'/event/appImgConfig/remove'
}

// 查询
export function axios_getPage(params) {
   return request({
      url: api.page ,
      method: 'get',
      params
   })
}
// 添加
export function axios_add(data) {
   return request({
      url: api.add ,
      method: 'post',
      data:qs.stringify(data)
   })
}
// 编辑
export function axios_modify(data) {
   return request({
      url: api.modify ,
      method: 'post',
      data:qs.stringify(data)
   })
}
// 删除
export function axios_remove(params) {
   return request({
      url: api.remove ,
      method: 'post',
      params
   })
}